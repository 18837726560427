import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Outlet } from "react-router-dom";
import ZaloChatButton from "../components/ZaloChatButton"; // Import the Zalo chat button

const GuestLayout = () => {
  return (
    <div>
      <Navbar />
      {/* <main className="p-4"> */}
      <main>
        <Outlet /> {/* Hiển thị các routes con */}
      </main>
      <Footer />
      <ZaloChatButton /> {/* Thêm nút chat Zalo ở đây */}
    </div>
  );
};

export default GuestLayout;
