import React from "react";
import { useNavigate } from "react-router-dom";

function ProductSection({ img, bgColor, title, id }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${id}`); // Navigate to the product detail page
  };

  return (
    <section className="product-section" style={{ backgroundColor: bgColor }}>
      <div className="product-image-container" onClick={handleClick}>
        <img
          src={img}
          alt={`${title} Image`}
          className="product-image"
          style={{
            cursor: "pointer",
            width: "100%",
            // maxWidth: "1200px",
            height: "auto",
            margin: "0 auto",
            display: "block",
          }}
        />
      </div>
    </section>
  );
}

export default ProductSection;
