import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import ManageUsers from "../pages/admin/ManageUsers";
import ManageProducts from "../pages/admin/ManageProducts";
import ManageAbout from "../pages/admin/ManageAbout";
import ManageBanner from "../pages/admin/ManageBanner";
import ChangePassword from "../pages/admin/ChangePassword";
import ChangeZaloOAID from "../pages/admin/ManageZalo";
import Login from "../pages/admin/Login";
import Sidebar from "../components/Sidebar";
import PrivateRoute from "./PrivateRoute"; // Correct import for PrivateRoute

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route
        path="/*"
        element={
          <div className="flex min-h-screen">
            <Sidebar />
            <main className="flex-grow p-6 bg-gray-100">
              <Routes>
                <Route
                  path="dashboard"
                  element={<PrivateRoute element={<Dashboard />} />}
                />
                <Route
                  path="manage-users"
                  element={<PrivateRoute element={<ManageUsers />} />}
                />
                <Route
                  path="manage-products"
                  element={<PrivateRoute element={<ManageProducts />} />}
                />
                <Route
                  path="manage-about"
                  element={<PrivateRoute element={<ManageAbout />} />}
                />
                <Route
                  path="manage-banner"
                  element={<PrivateRoute element={<ManageBanner />} />}
                />
                <Route
                  path="change-password"
                  element={<PrivateRoute element={<ChangePassword />} />}
                />
                <Route
                  path="change-zalooaid"
                  element={<PrivateRoute element={<ChangeZaloOAID />} />}
                />
              </Routes>
            </main>
          </div>
        }
      />
    </Routes>
  );
};

export default AdminRoutes;
