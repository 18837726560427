import React, { useEffect, useState } from "react";
import { get } from "../../services/axiosClient";
import { useTranslation } from "react-i18next";
import { ProgressSpinner } from "primereact/progressspinner"; // Import spinner từ PrimeReact

function About() {
  const { i18n } = useTranslation();
  const [bannerImgVn, setBannerImgVn] = useState("");
  const [bannerImgEng, setBannerImgEng] = useState("");
  const [loading, setLoading] = useState(true); // Trạng thái loading

  useEffect(() => {
    get("/About/1")
      .then((response) => {
        setBannerImgVn(response.imageUrl);
        setBannerImgEng(response.imageUrlEng);
        setLoading(false); // Đặt loading thành false sau khi lấy dữ liệu thành công
      })
      .catch((error) => {
        // console.error("Error fetching banner images:", error);
        setLoading(false); // Đặt loading thành false trong trường hợp có lỗi
      });
  }, []);

  const bannerImage = i18n.language === "vi" ? bannerImgVn : bannerImgEng;

  // Nếu đang loading, hiển thị spinner
  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ProgressSpinner />
      </div>
    );
  }

  return (
    <div className="About">
      <header className="hero-section">
        {bannerImage && (
          <img
            src={`data:image/png;base64,${bannerImage}`}
            alt="About Banner"
            className="w-full sm:w-4/5 lg:w-3/4 xl:w-2/3 mx-auto my-6"
            style={{
              maxWidth: "1200px",
              height: "auto",
              display: "block",
            }}
          />
        )}
      </header>
    </div>
  );
}

export default About;
