import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify"; // For notifications

const isAuthenticated = () => {
  // Check if the token exists and is valid
  const token = localStorage.getItem("accessToken");
  // Optionally, you can add additional logic to verify the token here
  return !!token;
};

const PrivateRoute = ({ element }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (!isAuthenticated()) {
      toast.error("Bạn phải đăng nhập để truy cập trang này.");
      setIsAuthorized(false);
    } else {
      setIsAuthorized(true);
    }
  }, []);

  if (isAuthorized === null) return null; // Render nothing while checking

  return isAuthorized ? element : <Navigate to="/admin/login" />;
};

export default PrivateRoute;
