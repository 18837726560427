import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { get } from "../services/axiosClient";
import logo from "../img/logo.png";
import flagEnglish from "../assets/images/flagEnglish.png";
import flagVietnamese from "../assets/images/flagVietnamese.png";

function Navbar() {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const mobileMenuRef = useRef(null);

  // Fetch product names and productIds from the API
  useEffect(() => {
    get("/Product")
      .then((response) => {
        const activeProducts = response.filter((product) => product.isActive);
        setProducts(
          activeProducts.map((product) => ({
            productId: product.productId,
            productName: product.productName,
          }))
        );
      })
      .catch((error) => {
        // Handle error
      });
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
        setShowMobileDropdown(false); // Close mobile dropdown as well
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 w-full bg-gradient-to-b from-gray-50/90 to-gray-300/90 backdrop-blur-lg shadow-md z-50 md:h-[100px]">
      <div className="container mx-auto flex items-center justify-between px-4 py-3 md:py-4">
        {/* Hamburger icon for mobile */}
        <button
          className="text-black md:hidden"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <i className="pi pi-bars text-2xl"></i>
        </button>

        {/* Desktop Menu */}
        <ul className="hidden md:flex gap-4 items-center w-full justify-center">
          <li>
            <Link className="text-black no-underline hover:underline" to="/">
              {t("home")}
            </Link>
          </li>
          <li>
            <Link
              className="text-black no-underline hover:underline"
              to="/about"
            >
              {t("about")}
            </Link>
          </li>

          {/* Logo centered between About and Products */}
          <li className="mx-4">
            <Link to="/">
              <img src={logo} alt="Logo" className="h-10 md:h-12" />
            </Link>
          </li>

          <li
            className="relative"
            onMouseEnter={() => setShowDropdown(true)}
            onMouseLeave={() => setShowDropdown(false)}
          >
            <Link className="text-black no-underline hover:underline">
              {t("products")}
            </Link>
            {showDropdown && (
              <ul className="absolute top-full left-0 bg-white shadow-lg p-2 w-48 z-50">
                {products.map((product, index) => (
                  <li key={index} className="p-2 hover:bg-gray-200">
                    <Link
                      to={`/product/${product.productId}`}
                      className="block text-black no-underline"
                    >
                      {product.productName}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li>
            <Link
              className="text-black no-underline hover:underline"
              to="/contact"
            >
              {t("contact")}
            </Link>
          </li>

          {/* Language buttons */}
          <div className="hidden md:flex gap-2">
            <button
              onClick={() => changeLanguage("en")}
              className="flex items-center"
            >
              <img src={flagEnglish} alt="English" className="h-6 mr-1" />{" "}
            </button>
            <button
              onClick={() => changeLanguage("vi")}
              className="flex items-center"
            >
              <img src={flagVietnamese} alt="Vietnamese" className="h-6 mr-1" />{" "}
            </button>
          </div>
        </ul>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div
          ref={mobileMenuRef}
          className="flex flex-col items-center p-4 md:hidden"
        >
          <Link to="/" className="mb-4">
            <img src={logo} alt="Logo" className="h-10" />
          </Link>
          <ul className="flex flex-col items-center gap-4">
            <li>
              <Link className="text-black no-underline hover:underline" to="/">
                {t("home")}
              </Link>
            </li>
            <li>
              <Link
                className="text-black no-underline hover:underline"
                to="/about"
              >
                {t("about")}
              </Link>
            </li>
            <li className="relative">
              <button
                onClick={() => setShowMobileDropdown(!showMobileDropdown)}
                className="text-black no-underline hover:underline"
              >
                {t("products")}
              </button>
              {showMobileDropdown && (
                <ul className="flex flex-col items-center bg-white shadow-lg p-2 w-48 z-50">
                  {products.map((product, index) => (
                    <li key={index} className="p-2 hover:bg-gray-200">
                      <Link
                        to={`/product/${product.productId}`}
                        className="block text-black no-underline"
                      >
                        {product.productName}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li>
              <Link
                className="text-black no-underline hover:underline"
                to="/contact"
              >
                {t("contact")}
              </Link>
            </li>
            {/* Language buttons for mobile */}
            <div className="flex gap-2 mt-4">
              <button
                onClick={() => changeLanguage("en")}
                className="flex items-center"
              >
                <img src={flagEnglish} alt="English" className="h-6 mr-1" />
              </button>
              <button
                onClick={() => changeLanguage("vi")}
                className="flex items-center"
              >
                <img
                  src={flagVietnamese}
                  alt="Vietnamese"
                  className="h-6 mr-1"
                />
              </button>
            </div>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
