import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const fullname = localStorage.getItem("fullname");

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/admin/login");
  };

  return (
    <div className="w-64 bg-gray-800 text-white h-screen flex flex-col">
      <div className="p-4 text-lg font-semibold">Welcome, {fullname}!</div>
      <nav className="mt-4 flex-grow">
        <ul>
          {/* <li
            className={`p-2 ${
              location.pathname === "/admin/dashboard"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/dashboard" className="flex items-center">
              Dashboard
            </Link>
          </li> */}
          <li
            className={`p-2 ${
              location.pathname === "/admin/manage-users"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/manage-users" className="flex items-center">
              Quản lí Users
            </Link>
          </li>
          <li
            className={`p-2 ${
              location.pathname === "/admin/manage-products"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/manage-products" className="flex items-center">
              Quản lí Products
            </Link>
          </li>
          <li
            className={`p-2 ${
              location.pathname === "/admin/manage-about"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/manage-about" className="flex items-center">
              Quản lí About
            </Link>
          </li>
          <li
            className={`p-2 ${
              location.pathname === "/admin/manage-banner"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/manage-banner" className="flex items-center">
              Quản lí Banner
            </Link>
          </li>
          <li
            className={`p-2 ${
              location.pathname === "/admin/change-password"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/change-password" className="flex items-center">
              Đổi mật khẩu
            </Link>
          </li>
          <li
            className={`p-2 ${
              location.pathname === "/admin/change-zalooaid"
                ? "bg-gray-700"
                : "hover:bg-gray-700"
            }`}
          >
            <Link to="/admin/change-zalooaid" className="flex items-center">
              Đổi thông tin Zalo
            </Link>
          </li>
        </ul>
      </nav>
      <div className="p-4 mt-auto">
        <Button
          icon="pi pi-sign-out"
          label="Logout"
          aria-label="Logout"
          className="w-full p-button-danger p-button-text flex align-items-center rounded-lg px-4 py-2 hover:bg-red-600 hover:text-white transition-colors duration-300"
          onClick={handleLogout}
        />
      </div>
    </div>
  );
};

export default Sidebar;
