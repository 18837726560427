import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { get, put } from "../../services/axiosClient";
import { toast } from "react-toastify"; // Import toast directly
import { ProgressSpinner } from "primereact/progressspinner"; // Nhập spinner từ PrimeReact

const ManageProducts = () => {
  const nameid = localStorage.getItem("nameid");

  const [isUpdating, setIsUpdating] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);

  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    productId: 0,
    homeImageUrl: "",
    homeImageUrlEng: "",
    detailImageUrl: "",
    detailImageUrlEng: "",
    tikiUrl: "",
    shopeeUrl: "",
    tiktokShopUrl: "",
    lazadaUrl: "",
    isActive: true,
    updatedDate: new Date().toISOString(),
    updatedByUserId: 0,
    productName: "",
  });
  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);

  // Hàm lấy dữ liệu sản phẩm và thông tin người dùng
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // Lấy dữ liệu sản phẩm từ API
        const productData = await get("Product/filter");
        setProducts(productData);

        // Dùng updatedByUserId để lấy thông tin người dùng
        const userIds = [
          ...new Set(
            productData.map((p) => p.updatedByUserId).filter((id) => id)
          ),
        ];
        const userPromises = userIds.map((id) => get(`User/${id}`));

        // Lưu thông tin người dùng
        const userResults = await Promise.all(userPromises);
        const userMap = userResults.reduce((acc, user) => {
          acc[user.userId] = user.fullName;
          return acc;
        }, {});

        setUsers(userMap);
      } catch (error) {
        console.error("Failed to fetch products or users:", error);
        toast.error("Lỗi khi tải sản phẩm, vui lòng thử lại sau.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  // Hàm xử lý khi cập nhật sản phẩm
  const handleUpdate = async () => {
    if (selectedProduct) {
      setIsUpdating(true);
      setLoadingDialog(true);

      try {
        const authenticatedUserId = localStorage.getItem("nameid");

        const updatedData = {
          productId: selectedProduct.productId,
          updatedDate: new Date().toISOString(),
          updatedByUserId: authenticatedUserId,
        };

        // Chỉ thêm các trường đã thay đổi
        if (formData.productName !== selectedProduct.productName) {
          updatedData.productName = formData.productName;
        }
        if (formData.homeImageUrl !== selectedProduct.homeImageUrl) {
          updatedData.homeImageUrl = formData.homeImageUrl;
        }
        if (formData.homeImageUrlEng !== selectedProduct.homeImageUrlEng) {
          updatedData.homeImageUrlEng = formData.homeImageUrlEng;
        }
        if (formData.detailImageUrl !== selectedProduct.detailImageUrl) {
          updatedData.detailImageUrl = formData.detailImageUrl;
        }
        if (formData.detailImageUrlEng !== selectedProduct.detailImageUrlEng) {
          updatedData.detailImageUrlEng = formData.detailImageUrlEng;
        }
        if (formData.tikiUrl !== selectedProduct.tikiUrl) {
          updatedData.tikiUrl = formData.tikiUrl;
        }
        if (formData.shopeeUrl !== selectedProduct.shopeeUrl) {
          updatedData.shopeeUrl = formData.shopeeUrl;
        }
        if (formData.tiktokShopUrl !== selectedProduct.tiktokShopUrl) {
          updatedData.tiktokShopUrl = formData.tiktokShopUrl;
        }
        if (formData.lazadaUrl !== selectedProduct.lazadaUrl) {
          updatedData.lazadaUrl = formData.lazadaUrl;
        }
        if (formData.isActive !== selectedProduct.isActive) {
          updatedData.isActive = formData.isActive;
        }

        // console.log('Dữ liệu cập nhật:', updatedData);

        // Gửi yêu cầu cập nhật
        await put(`Product/${selectedProduct.productId}`, updatedData);

        // Reload trang sau khi cập nhật thành công
        window.location.reload();

        toast.success("Cập nhật sản phẩm thành công!");
      } catch (error) {
        // console.error("Cập nhật sản phẩm thất bại:", error);
        toast.error("Cập nhật sản phẩm thất bại.");
      } finally {
        setLoadingDialog(false);
        setIsUpdating(false);
      }
    }
  };

  // Hàm xử lý thay đổi hình ảnh
  const handleImageChange = (e, field) => {
    const file = e.target.files[0];

    if (file) {
      // Check if the file size exceeds 1.5MB (1.5 * 1024 * 1024 bytes)
      if (file.size > 4 * 1024 * 1024) {
        toast.error("File hình up lên không được > 4MB!");
        // Clear the file input and corresponding field in the formData
        e.target.value = ""; // Clear the file input
        setFormData((prevData) => ({
          ...prevData,
          [field]: "", // Clear the image field in the formData
        }));
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          [field]: reader.result.split(",")[1], // base64 part only
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  // Hàm hiển thị ảnh trong bảng
  const imageBodyTemplate = (rowData, field) => {
    const imageData = rowData[field];
    if (imageData) {
      const src = `${imageData}`;
      return <img src={src} alt={field} className="w-24 h-24 object-contain" />;
    }
    return "No image";
  };

  // Mở hộp thoại chỉnh sửa sản phẩm
  const openDialog = (product) => {
    setSelectedProduct(product);
    setFormData({
      productId: product.productId,
      homeImageUrl: product.homeImageUrl || "",
      homeImageUrlEng: product.homeImageUrlEng || "",
      detailImageUrl: product.detailImageUrl || "",
      detailImageUrlEng: product.detailImageUrlEng || "",
      tikiUrl: product.tikiUrl || "",
      shopeeUrl: product.shopeeUrl || "",
      tiktokShopUrl: product.tiktokShopUrl || "",
      lazadaUrl: product.lazadaUrl || "",
      isActive: product.isActive,
      updatedDate: product.updatedDate
        ? new Date(product.updatedDate).toISOString()
        : new Date().toISOString(), // Kiểm tra và sử dụng giá trị mặc định
      updatedByUserId: product.updatedByUserId,
      productName: product.productName || "",
    });
    setDialogVisible(true);
  };

  // Đóng hộp thoại
  const closeDialog = () => {
    setDialogVisible(false);
    setSelectedProduct(null);
  };

  // Nút sửa sản phẩm
  const editButtonTemplate = (rowData) => {
    return (
      <Button
        label="Sửa"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => openDialog(rowData)}
      />
    );
  };

  // Hiển thị số thứ tự
  const noBodyTemplate = (rowData, { rowIndex }) => {
    return rowIndex + 1;
  };

  // Hiển thị tên người cập nhật
  const userBodyTemplate = (rowData) => {
    return users[rowData.updatedByUserId] || "N/A";
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Quản lý Sản phẩm</h2>
      {isUpdating ? (
        <div className="flex justify-center items-center">
          <span>Loading...</span>
        </div>
      ) : (
        <DataTable
          value={products}
          loading={loading}
          paginator
          rows={3}
          selectionMode="single"
          selection={selectedProduct}
        >
          <Column
            header="STT"
            body={noBodyTemplate}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="productName"
            header="Tên sản phẩm"
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="homeImageUrl"
            header="Hình ảnh trang chủ"
            body={(rowData) => imageBodyTemplate(rowData, "homeImageUrl")}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="homeImageUrlEng"
            header="Hình ảnh trang chủ (Eng)"
            body={(rowData) => imageBodyTemplate(rowData, "homeImageUrlEng")}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="detailImageUrl"
            header="Hình ảnh chi tiết"
            body={(rowData) => imageBodyTemplate(rowData, "detailImageUrl")}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="detailImageUrlEng"
            header="Hình ảnh chi tiết (Eng)"
            body={(rowData) => imageBodyTemplate(rowData, "detailImageUrlEng")}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="tikiUrl"
            header="Tiki URL"
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="shopeeUrl"
            header="Shopee URL"
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="tiktokShopUrl"
            header="TikTok Shop URL"
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="lazadaUrl"
            header="Lazada URL"
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="isActive"
            header="Trạng thái"
            body={(rowData) => (rowData.isActive ? "Hiển thị" : "Tắt")}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="updatedDate"
            header="Thời gian cập nhật"
            body={(rowData) =>
              new Date(rowData.updatedDate).toLocaleDateString()
            }
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            field="updatedByUserId"
            header="Người cập nhật"
            body={userBodyTemplate}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
          <Column
            header="Hành động"
            body={editButtonTemplate}
            className="border border-gray-300 p-2"
            headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
          />
        </DataTable>
      )}
      <Dialog
        header={
          <span className="text-3xl font-bold">
            Cập nhật thông tin sản phẩm
          </span>
        }
        visible={dialogVisible}
        onHide={closeDialog}
        footer={
          <div className="flex justify-end gap-2">
            <Button
              label="Hủy"
              icon="pi pi-times"
              onClick={closeDialog}
              className="p-button-text"
            />
            {loadingDialog ? (
              <ProgressSpinner style={{ width: "30px", height: "30px" }} />
            ) : (
              <Button
                label="Cập nhật"
                icon="pi pi-check"
                onClick={handleUpdate}
                className="p-button-primary"
              />
            )}
          </div>
        }
        className="bg-gray-800 p-4 text-white rounded-2xl"
        style={{ width: "50vw" }}
      >
        {selectedProduct && (
          <div className="p-fluid">
            <div className="flex-1">
              <label>Tên sản phẩm:</label>
              <input
                type="text"
                value={formData.productName}
                onChange={(e) =>
                  setFormData({ ...formData, productName: e.target.value })
                }
                className="p-inputtext p-component text-black"
              />
            </div>
            <div className="flex gap-4 mb-4">
              <div className="flex-1">
                <label htmlFor="homeImageUrl">Hình ảnh trang chủ</label>
                <input
                  id="homeImageUrl"
                  type="text"
                  value={formData.homeImageUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, homeImageUrl: e.target.value })
                  }
                  className="p-inputtext p-component w-full text-black"
                  placeholder="Nhập URL hình ảnh"
                />
                {formData.homeImageUrl && (
                  <img
                    src={formData.homeImageUrl}
                    alt="Hình ảnh trang chủ"
                    className="mt-2 w-full h-auto object-contain"
                  />
                )}
              </div>

              <div className="flex-1">
                <label htmlFor="homeImageUrlEng">
                  Hình ảnh trang chủ (Eng)
                </label>
                <input
                  id="homeImageUrlEng"
                  type="text"
                  value={formData.homeImageUrlEng}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      homeImageUrlEng: e.target.value,
                    })
                  }
                  className="p-inputtext p-component w-full text-black"
                  placeholder="Nhập URL hình ảnh"
                />
                {formData.homeImageUrlEng && (
                  <img
                    src={formData.homeImageUrlEng}
                    alt="Hình ảnh trang chủ Eng"
                    className="mt-2 w-full h-auto object-contain"
                  />
                )}
              </div>
            </div>
            <div className="flex gap-4 mb-4">
              <div className="flex-1">
                <label htmlFor="detailImageUrl">Hình ảnh chi tiết</label>
                <input
                  id="detailImageUrl"
                  type="text"
                  value={formData.detailImageUrl}
                  onChange={(e) =>
                    setFormData({ ...formData, detailImageUrl: e.target.value })
                  }
                  className="p-inputtext p-component w-full text-black"
                  placeholder="Nhập URL hình ảnh"
                />
                {formData.detailImageUrl && (
                  <img
                    src={formData.detailImageUrl}
                    alt="Hình ảnh chi tiết"
                    className="mt-2 w-full h-auto object-contain"
                  />
                )}
              </div>

              <div className="flex-1">
                <label htmlFor="detailImageUrlEng">
                  Hình ảnh chi tiết (Eng)
                </label>
                <input
                  id="detailImageUrlEng"
                  type="text"
                  value={formData.detailImageUrlEng}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      detailImageUrlEng: e.target.value,
                    })
                  }
                  className="p-inputtext p-component w-full text-black"
                  placeholder="Nhập URL hình ảnh"
                />
                {formData.detailImageUrlEng && (
                  <img
                    src={formData.detailImageUrlEng}
                    alt="Hình ảnh chi tiết Eng"
                    className="mt-2 w-full h-auto object-contain"
                  />
                )}
              </div>
            </div>
            <div className="p-field">
              <label htmlFor="tikiUrl">Tiki URL</label>
              <input
                id="tikiUrl"
                type="text"
                value={formData.tikiUrl}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    tikiUrl: e.target.value,
                  }))
                }
                className="p-inputtext p-component w-full text-black"
              />
            </div>

            <div className="p-field">
              <label htmlFor="shopeeUrl">Shopee URL</label>
              <input
                id="shopeeUrl"
                type="text"
                value={formData.shopeeUrl}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    shopeeUrl: e.target.value,
                  }))
                }
                className="p-inputtext p-component w-full text-black"
              />
            </div>

            <div className="p-field">
              <label htmlFor="tiktokShopUrl">TikTok Shop URL</label>
              <input
                id="tiktokShopUrl"
                type="text"
                value={formData.tiktokShopUrl}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    tiktokShopUrl: e.target.value,
                  }))
                }
                className="p-inputtext p-component w-full text-black"
              />
            </div>
            <div className="p-field">
              <label htmlFor="lazadaUrl">Lazada URL</label>
              <input
                id="lazadaUrl"
                type="text"
                value={formData.lazadaUrl}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    lazadaUrl: e.target.value,
                  }))
                }
                className="p-inputtext p-component w-full text-black"
              />
            </div>
            <div className="p-field-checkbox">
              <input
                id="isActive"
                type="checkbox"
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    isActive: e.target.checked,
                  }))
                }
                className="p-checkbox p-component"
              />
              <label htmlFor="isActive">Hiển thị</label>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default ManageProducts;
