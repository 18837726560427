import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { get } from "../../services/axiosClient";
import { useTranslation } from "react-i18next";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import tikiLogo from "../../assets/images/tiki-logo.png";
import shopeeLogo from "../../assets/images/shopee-logo.png";
import tiktokLogo from "../../assets/images/tiktok-logo.png";
import lazadaLogo from "../../assets/images/lazada-logo.png";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import { ProgressSpinner } from "primereact/progressspinner";
import ZaloChatButton from "../../components/ZaloChatButton";

function ProductDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!id) {
      navigate("/"); // Navigate to home if ID is not present
      return;
    }

    // Fetch product details from API
    get(`/Product/${id}`)
      .then((response) => {
        if (!response) {
          throw new Error("Product not found"); // Handle case where response is not valid
        }
        setProduct(response);
        setLoading(false);
      })
      .catch((error) => {
        setError("An error occurred while fetching product details.");
        setLoading(false);
        navigate("/"); // Navigate to home on error
      });
  }, [id, navigate]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <ProgressSpinner />
      </div>
    );
  }

  if (error) return <div>{error}</div>;

  const detailImage = product
    ? i18n.language === "vi"
      ? product.detailImageUrl
      : product.detailImageUrlEng
    : "";

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex flex-1">
        <div className="flex-1 flex flex-col items-center justify-center">
          {product ? (
            <>
              <img
                src={`${detailImage}`}
                alt={product.title || "Product Detail"}
                className="w-full max-w-full h-auto"
                style={{ maxWidth: "1920px" }}
              />
              <p className="text-lg text-center">{product.description}</p>
            </>
          ) : (
            <div>No product found</div>
          )}
        </div>
        <div className="w-11 md:w-14 fixed right-4 top-1/2 transform -translate-y-1/2 flex flex-col gap-4 z-50 bg-white bg-opacity-30 rounded-3xl">
          {product?.tikiUrl && (
            <a href={product.tikiUrl} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center p-2">
              <img src={tikiLogo} alt="Tiki" className="w-12 h-8" />
            </a>
          )}
          {product?.shopeeUrl && (
            <a href={product.shopeeUrl} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center p-2">
              <img src={shopeeLogo} alt="Shopee" className="w-11 h-7" />
            </a>
          )}
          {product?.tiktokShopUrl && (
            <a href={product.tiktokShopUrl} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center p-2">
              <img src={tiktokLogo} alt="TikTok" className="w-8 h-8" />
            </a>
          )}
          {product?.lazadaUrl && (
            <a href={product.lazadaUrl} target="_blank" rel="noopener noreferrer" className="flex items-center justify-center p-2">
              <img src={lazadaLogo} alt="Lazada" className="w-10 h-8" />
            </a>
          )}
        </div>
      </main>
      <Footer />
      <ScrollToTopButton />
      <ZaloChatButton />
    </div>
  );
}

export default ProductDetail;