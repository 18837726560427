import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify"; // Import toast directly
import { get, put, gets } from "../../services/axiosClient"; // Use 'gets' for authorized requests

const ManageBanner = () => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [formData, setFormData] = useState({
    bannerId: 0,
    videoUrl: "",
    bannerImgVn: "",
    bannerImgEng: "",
    isActive: true,
    updatedDate: new Date().toISOString(),
    updatedByUserId: 0,
  });
  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [userNames, setUserNames] = useState({}); // Store user data for updatedByUserId

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const data = await get("Banner");
        setBanners(data);
      } catch (error) {
        // console.error(
        //   "Failed to fetch banners:",
        //   error.response ? error.response.data : error.message
        // );
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, []);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const uniqueUserIds = [
          ...new Set(banners.map((banner) => banner.updatedByUserId)),
        ];

        const usersData = await Promise.all(
          uniqueUserIds.map(async (userId) => {
            try {
              const user = await gets(`user/${userId}`);
              return { userId, fullName: user.fullName || "N/A" };
            } catch (error) {
              return { userId, fullName: "N/A" };
            }
          })
        );

        const userNameMap = usersData.reduce((acc, user) => {
          acc[user.userId] = user.fullName;
          return acc;
        }, {});

        setUserNames(userNameMap);
      } catch (error) {
        // console.error(
        //   "Failed to fetch user names:",
        //   error.response ? error.response.data : error.message
        // );
      }
    };

    if (banners.length > 0) {
      fetchUserNames();
    }
  }, [banners]);

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          [field]: reader.result.split(",")[1], // Remove the data URL prefix
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = async () => {
    if (selectedBanner) {
      try {
        const authenticatedUserId = localStorage.getItem("nameid");

        const updatedData = {
          ...formData,
          bannerId: selectedBanner.bannerId,
          updatedDate: new Date().toISOString(),
          updatedByUserId: authenticatedUserId, // Ensure this is a number
        };

        const response = await put(
          `Banner/${selectedBanner.bannerId}`,
          updatedData
        );
        // alert("Banner updated successfully!");
        toast.success("Cập nhật thành công!"); // Use toast directly

        const updatedBanners = await get("Banner");
        setBanners(updatedBanners);
        setDialogVisible(false);
        setSelectedBanner(null);
        setFormData({
          bannerId: 0,
          videoUrl: "",
          bannerImgVn: "",
          bannerImgEng: "",
          isActive: true,
          updatedDate: new Date().toISOString(),
          updatedByUserId: 0,
        });
      } catch (error) {
        // console.error(
        //   "Failed to update banner:",
        //   error.response ? error.response.data : error.message
        // );
        // alert("Failed to update banner!");
        toast.error("Cập nhật lỗi!"); // Use toast directly for error
      }
    }
  };

  const imageBodyTemplate = (rowData, field) => {
    const imageData = rowData[field];
    if (imageData) {
      const src = `data:image/png;base64,${imageData}`;
      return <img src={src} alt={field} className="w-24 h-24 object-contain" />;
    }
    return "No image";
  };

  const openDialog = (banner) => {
    setSelectedBanner(banner);
    setFormData({
      bannerId: banner.bannerId,
      videoUrl: banner.videoUrl || "",
      bannerImgVn: banner.bannerImgVn || "",
      bannerImgEng: banner.bannerImgEng || "",
      isActive: banner.isActive,
      updatedDate: new Date(banner.updatedDate).toISOString(),
      updatedByUserId: banner.updatedByUserId,
    });
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setSelectedBanner(null);
  };

  const editButtonTemplate = (rowData) => {
    return (
      <Button
        label="Sửa"
        icon="pi pi-pencil"
        className="p-button-warning"
        onClick={() => openDialog(rowData)}
      />
    );
  };

  const statusBodyTemplate = (rowData) => {
    return rowData.isActive ? "Hiển thị" : "Tắt";
  };

  const userBodyTemplate = (rowData) => {
    return userNames[rowData.updatedByUserId] || "N/A";
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Quản lí Banner</h2>

      <DataTable
        value={banners}
        loading={loading}
        paginator
        rows={5}
        onRowSelect={(e) => openDialog(e.data)}
        selectionMode="single"
        selection={selectedBanner}
        className="border border-gray-200"
      >
        <Column
          field="bannerImgVn"
          header="Hình ảnh (VN)"
          body={(rowData) => imageBodyTemplate(rowData, "bannerImgVn")}
        />
        <Column
          field="bannerImgEng"
          header="Hình ảnh (Eng)"
          body={(rowData) => imageBodyTemplate(rowData, "bannerImgEng")}
        />
        {/* <Column field="videoUrl" header="Video URL" /> */}
        <Column field="isActive" header="Status" body={statusBodyTemplate} />
        <Column
          field="updatedDate"
          header="Thời gian cập nhật"
          body={(rowData) => new Date(rowData.updatedDate).toLocaleDateString()}
        />
        <Column
          field="updatedByUserId"
          header="Người cập nhật"
          body={userBodyTemplate}
        />
        <Column header="Trạng thái" body={editButtonTemplate} />
      </DataTable>

      <Dialog
        visible={dialogVisible}
        onHide={closeDialog}
        header={<span className="text-3xl font-bold">Cập nhật Banner</span>}
        footer={
          <div className="flex justify-end gap-2">
            <Button
              label="Hủy"
              icon="pi pi-times"
              onClick={closeDialog}
              className="p-button-text"
            />
            <Button
              label="Cập nhật"
              icon="pi pi-check"
              onClick={handleUpdate}
            />
          </div>
        }
        className="bg-gray-800 p-4 text-white rounded-2xl"
        style={{ width: "50vw" }}
      >
        <div className="p-fluid">
          <div className="flex gap-4 mb-4">
            <div className="flex-1">
              <label htmlFor="bannerImgVn">Banner Image (VN):</label>
              <input
                id="bannerImgVn"
                type="file"
                onChange={(e) => handleImageChange(e, "bannerImgVn")}
                className="p-inputtext p-component"
              />
              {formData.bannerImgVn && (
                <img
                  src={`data:image/png;base64,${formData.bannerImgVn}`}
                  alt="Banner Image (VN) Preview"
                  className="mt-2 w-full h-auto object-contain"
                />
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="bannerImgEng">Banner Image (Eng):</label>
              <input
                id="bannerImgEng"
                type="file"
                onChange={(e) => handleImageChange(e, "bannerImgEng")}
                className="p-inputtext p-component"
              />
              {formData.bannerImgEng && (
                <img
                  src={`data:image/png;base64,${formData.bannerImgEng}`}
                  alt="Banner Image (Eng) Preview"
                  className="mt-2 w-full h-auto object-contain"
                />
              )}
            </div>
          </div>

          {/* <div className="p-field">
            <label htmlFor="videoUrl">Video URL:</label>
            <input
              id="videoUrl"
              type="text"
              value={formData.videoUrl}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  videoUrl: e.target.value,
                }))
              }
              className="p-inputtext p-component"
            />
          </div> */}

          <div className="p-field-checkbox">
            <input
              id="isActive"
              type="checkbox"
              checked={formData.isActive}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  isActive: e.target.checked,
                }))
              }
              className="p-checkbox p-component"
            />
            <label htmlFor="isActive">Hiển thị</label>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageBanner;
