import { Link, Outlet } from "react-router-dom";
import logo from "../../img/logo.png"; 

const Dashboard = () => {
  const userName = "User"; // Thay thế bằng logic lấy người dùng thực tế

  return (
    <div>
      <img src={logo} alt="Logo" className="w-32 h-auto mb-4 grid place-items-center" /> {/* Điều chỉnh kích thước logo nếu cần */}
    </div>
  );
};

export default Dashboard;
