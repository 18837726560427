import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { put } from "../../services/axiosClient";

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    const nameid = localStorage.getItem("nameid"); // Get user ID from local storage or other authentication method
    const token = localStorage.getItem("token"); // Get the token from local storage

    try {
      await put(
        `Auth/UpdatePassword/update-password/${nameid}`,
        { oldPassword, newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setSuccess("Password updated successfully");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setError("");
    } catch (err) {
      // console.error("Failed to change password:", err);
      setError("Failed to change password. Please try again.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold mb-6 text-gray-800">
        Đổi mật khẩu
      </h2>

      <div className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="oldPassword" className="text-gray-600 mb-1">
            Mật khẩu hiện tại
          </label>
          <InputText
            id="oldPassword"
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            className="p-inputtext p-component border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="newPassword" className="text-gray-600 mb-1">
            Mật khẩu mới
          </label>
          <InputText
            id="newPassword"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="p-inputtext p-component border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="confirmPassword" className="text-gray-600 mb-1">
            Nhập lại mật khẩu mới
          </label>
          <InputText
            id="confirmPassword"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="p-inputtext p-component border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {error && <div className="text-red-500 mt-4">{error}</div>}
      {success && <div className="text-green-500 mt-4">{success}</div>}

      <Button
        label="Lưu"
        onClick={handleChangePassword}
        className="bg-green-500 hover:bg-green-600 text-white p-button-primary mt-6 w-full p-2 rounded-3xl"
      />
    </div>
  );
};

export default ChangePassword;
