import React from "react";
import { useTranslation } from "react-i18next";
import englishImage from "../../assets/images/CollabNow.png";
import vietnameseImage from "../../assets/images/DangKyHopTac.png";
import contactImage from "../../img/background-white.jpg"; // Adjust the path based on where your image is located

const Contact = () => {
  const { i18n } = useTranslation(); // Use i18n to get the current language

  return (
    <div className="contact-page p-8">
      {/* Conditionally render the image based on selected language */}
      <div className="contact-image flex justify-center my-72">
        {i18n.language === "en" ? (
          <a
            href="https://forms.gle/GmmJ3ePSMmiM4S3r9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={englishImage}
              alt="Contact English"
              className="max-w-full h-auto"
            />
          </a>
        ) : (
          <a
            href="https://forms.gle/GmmJ3ePSMmiM4S3r9"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={vietnameseImage}
              alt="Contact Vietnamese"
              className="max-w-full h-auto"
            />
          </a>
        )}
      </div>
      {/* <img src={contactImage} alt="Contact" className="w-full h-auto" /> */}
    </div>
  );
};

export default Contact;
