import React, { useEffect, useState } from "react";
import { get } from "../services/axiosClient"; // Ensure you have this import
import logozalo from "../assets/images/zalo-logo.png";

const ZaloChatButton = () => {
  const [zaloUrl, setZaloUrl] = useState("");

  useEffect(() => {
    // Fetch the Zalo OA ID URL from the API
    get("/About/1")
      .then((response) => {
        setZaloUrl(response.zaloUrl); // Assuming the API returns zaloUrl correctly
      })
      .catch((error) => {
        // console.error("Error fetching Zalo OA URL:", error);
      });
  }, []);

  return (
    <div>
      {zaloUrl && (
        <div className="fixed bottom-24 right-8">
          {/* Increase bottom spacing and decrease right spacing */}
          <a
            href={`https://zalo.me/${zaloUrl}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={logozalo}
              alt="Chat with us on Zalo"
              style={{ width: "60px", height: "60px" }}
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default ZaloChatButton;
