import React, { useState } from "react";
import logo from "../../img/logo.png";
import axiosClient from "../../services/axiosClient";
import { showSuccessToast, showErrorToast } from "../../services/toast";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axiosClient.post("/Auth/Login", {
        username: username,
        password: password,
      });

      if (res.status === 200 && res.data.success) {
        const token = res.data.result.accessToken;
        const user = jwtDecode(token); // Decode token to get user details

        // Save token and user data in localStorage
        localStorage.setItem("accessToken", token);
        localStorage.setItem("fullname", user.fullname || "Unknown");
        localStorage.setItem("nameid", user.nameid || "Unknown");
        localStorage.setItem("email", user.email || "Unknown");

        showSuccessToast(
          `Đăng nhập thành công! Chào mừng, ${user.fullname || "Người dùng"}`
        );
        // navigate("/admin/dashboard");
        navigate("/admin/manage-users");
      } else {
        showErrorToast("Tên đăng nhập hoặc mật khẩu không đúng!");
      }
    } catch (error) {
      // console.error("Lỗi đăng nhập:", error.message);
      // showErrorToast("Lỗi đăng nhập!");
      showErrorToast("Tên đăng nhập hoặc mật khẩu không đúng!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex min-h-screen">
      <div className="hidden lg:flex lg:w-1/2 bg-gray-200 justify-center items-center">
        <img src={logo} alt="Logo" className="h-48" />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col justify-center px-6 py-8">
        <h2 className="text-2xl font-bold mb-6">Đăng Nhập</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium mb-2"
            >
              Tên đăng nhập
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium mb-2"
            >
              Mật khẩu
            </label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>
          <button
            type="submit"
            className={`w-full py-2 px-4 bg-blue-500 text-white rounded-md hover:bg-blue-600 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Đang xử lý..." : "Đăng Nhập"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
