import React, { useEffect, useState } from "react";
import { get, put } from "../../services/axiosClient";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; // Import toast directly

function ManageZalo() {
  const { i18n } = useTranslation();
  const [zaloUrl, setZaloUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authenticatedUserId = localStorage.getItem("nameid");

  useEffect(() => {
    // Fetch existing Zalo URL
    get("/About/1")
      .then((response) => {
        setZaloUrl(response.zaloUrl);
        setLoading(false);
      })
      .catch((error) => {
        // console.error("Error fetching Zalo URL:", error);
        // setError("An error occurred while fetching Zalo URL.");
        setLoading(false);
      });
  }, []);

  const handleUpdate = () => {
    const payload = {
      zaloUrl,
      updatedByUserId: authenticatedUserId,
    };

    put("/About/1", payload)
      .then(() => {
        toast.success("Cập nhật thành công!"); // Use toast directly
      })
      .catch((error) => {
        // console.error("Error updating Zalo URL:", error);
        toast.error("Cập nhật lỗi!"); // Use toast directly for error
      });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="ManageZalo">
      <h2>Nhập số điện thoại zalo hoặc số điên thoại zalo OA</h2>
      <input
        type="text"
        value={zaloUrl}
        onChange={(e) => setZaloUrl(e.target.value)}
        placeholder="Enter Zalo URL"
        className="border p-2"
      />
      <button
        onClick={handleUpdate}
        className="bg-blue-500 text-white p-2 mt-2"
      >
        Cập nhật
      </button>
    </div>
  );
}

export default ManageZalo;
