import React, { useEffect, useState, lazy, Suspense } from "react";
import { get } from "../../services/axiosClient";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner"; // Import spinner từ PrimeReact

// Lazy load ProductSection
const ProductSection = lazy(() => import("../../components/ProductSection"));

function Home() {
  const { i18n } = useTranslation();
  const [bannerImgVn, setBannerImgVn] = useState("");
  const [bannerImgEng, setBannerImgEng] = useState("");
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true); // Trạng thái loading cho sản phẩm

  useEffect(() => {
    // Lấy banner images
    get("/Banner/1")
      .then((response) => {
        setBannerImgVn(response.bannerImgVn);
        setBannerImgEng(response.bannerImgEng);
      })
      .catch((error) => {
        // console.error("Error fetching banner images:", error);
      });

    // Lấy danh sách sản phẩm đã active
    get("/Product/filter?IsActive=true")
      .then(async (response) => {
        const productsWithImages = [];
        const addedProductIds = new Set(); // Theo dõi các ID sản phẩm đã thêm

        // Lấy chi tiết từng sản phẩm một và thêm vào mảng
        for (const product of response) {
          if (!addedProductIds.has(product.productId)) {
            try {
              const productDetail = await get(`/Product/${product.productId}`);
              productsWithImages.push(productDetail);
              addedProductIds.add(product.productId); // Đánh dấu sản phẩm đã được thêm
            } catch (error) {
              // console.error("Error fetching product details:", error);
            }
          }
        }

        setProducts(productsWithImages); // Cập nhật trạng thái sản phẩm sau khi đã lấy hết
        setLoadingProducts(false); // Đặt loading thành false khi hoàn tất
      })
      .catch((error) => {
        // console.error("Error fetching products:", error);
        setLoadingProducts(false); // Đặt loading thành false trong trường hợp có lỗi
      });
  }, []);

  const bannerImage = i18n.language === "vi" ? bannerImgVn : bannerImgEng;

  const getImageUrl = (product) =>
    i18n.language === "vi" ? product.homeImageUrl : product.homeImageUrlEng;

  return (
    <div className="Home">
      <header className="hero-section py-2">
        {bannerImage && (
          <img
            src={`data:image/png;base64,${bannerImage}`}
            alt="Banner"
            style={{
              width: "80%",
              maxWidth: "1200px",
              height: "auto",
              margin: "0 auto",
              display: "block",
            }}
          />
        )}
      </header>

      {/* Hiển thị spinner chỉ khi đang tải sản phẩm */}
      {loadingProducts ? (
        <div className="flex justify-center items-center min-h-screen">
          <ProgressSpinner />
        </div>
      ) : (
        products.map((product) => (
          <Link key={product.productId} to={`/product/${product.productId}`}>
            <Suspense fallback={<ProgressSpinner />}>
              <ProductSection
                img={getImageUrl(product)}
                bgColor={product.bgColor || "#FFFFFF"}
              />
            </Suspense>
          </Link>
        ))
      )}
    </div>
  );
}

export default Home;
