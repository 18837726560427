import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify"; // Import toast directly
import { get, put, gets } from "../../services/axiosClient"; // Use 'gets' for authorized requests

// Helper function to get the current date in ISO format
const getCurrentDateInISOFormat = () => {
  const now = new Date();
  const tzOffset = -now.getTimezoneOffset();
  const diffHours = Math.floor(tzOffset / 60);
  const diffMinutes = tzOffset % 60;
  now.setHours(now.getHours() + diffHours);
  now.setMinutes(now.getMinutes() + diffMinutes);
  return now.toISOString(); // Format: "YYYY-MM-DDTHH:MM:SS.SSSZ"
};

const ManageAbout = () => {
  const [aboutData, setAboutData] = useState([]);
  const [selectedAbout, setSelectedAbout] = useState(null);
  const [formData, setFormData] = useState({
    aboutId: 0,
    companyDescription: "",
    imageUrl: "",
    imageUrlEng: "",
    updatedDate: getCurrentDateInISOFormat(),
    updatedByUserId: 0,
  });
  const [loading, setLoading] = useState(true);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [userNames, setUserNames] = useState({}); // Store user data for updatedByUserId

  // Fetch about data
  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const data = await get("About");
        setAboutData(data);
      } catch (error) {
        // console.error("Failed to fetch about data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAboutData();
  }, []);

  // Fetch user names
  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const uniqueUserIds = [
          ...new Set(aboutData.map((item) => item.updatedByUserId)),
        ];
        const usersData = await Promise.all(
          uniqueUserIds.map(async (userId) => {
            try {
              const user = await gets(`user/${userId}`);
              return { userId, fullName: user.fullName || "N/A" };
            } catch {
              return { userId, fullName: "N/A" };
            }
          })
        );
        const userNameMap = usersData.reduce((acc, user) => {
          acc[user.userId] = user.fullName;
          return acc;
        }, {});
        setUserNames(userNameMap);
      } catch (error) {
        // console.error("Failed to fetch user names:", error);
      }
    };
    if (aboutData.length > 0) fetchUserNames();
  }, [aboutData]);

  // Handle image upload
  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          [field]: reader.result.split(",")[1],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  // Open dialog for editing
  const openDialog = (about) => {
    setSelectedAbout(about);
    setFormData({
      aboutId: about.aboutId,
      companyDescription: about.companyDescription || "",
      imageUrl: about.imageUrl || "",
      imageUrlEng: about.imageUrlEng || "",
      updatedDate: new Date(about.updatedDate).toISOString(),
      updatedByUserId: about.updatedByUserId,
    });
    setDialogVisible(true);
  };

  // Close dialog
  const closeDialog = () => {
    setDialogVisible(false);
    setSelectedAbout(null);
  };

  // Handle update
  const handleUpdate = async () => {
    if (selectedAbout) {
      try {
        const authenticatedUserId = localStorage.getItem("nameid");
        const updatedData = {
          ...formData,
          updatedDate: getCurrentDateInISOFormat(),
          updatedByUserId: authenticatedUserId,
        };

        await put(`About/${selectedAbout.aboutId}`, updatedData);
        // alert("About updated successfully!");
        toast.success("Cập nhật thành công!"); // Use toast directly

        const updatedAboutData = await get("About");
        setAboutData(updatedAboutData);
        closeDialog();
        resetFormData();
      } catch (error) {
        // console.error("Failed to update about:", error);
        // alert("Failed to update about!");
        toast.error("Cập nhật lỗi!"); // Use toast directly for error
      }
    }
  };

  // Reset form data
  const resetFormData = () => {
    setFormData({
      aboutId: 0,
      companyDescription: "",
      imageUrl: "",
      imageUrlEng: "",
      updatedDate: getCurrentDateInISOFormat(),
      updatedByUserId: 0,
    });
  };

  // Template for displaying images
  const imageBodyTemplate = (rowData, field) => {
    const imageData = rowData[field];
    if (imageData) {
      return (
        <img
          src={`data:image/jpeg;base64,${imageData}`}
          alt={field}
          className="w-24 h-24 object-contain"
        />
      );
    }
    return "No image";
  };

  // Template for displaying user names
  const userBodyTemplate = (rowData) => {
    return userNames[rowData.updatedByUserId] || "N/A";
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Quản lí About</h2>

      <DataTable
        value={aboutData}
        loading={loading}
        paginator
        rows={5}
        className="border border-gray-200"
        onRowSelect={(e) => openDialog(e.data)}
        selectionMode="single"
        selection={selectedAbout}
      >
        <Column
          header="Hình ảnh"
          body={(rowData) => imageBodyTemplate(rowData, "imageUrl")}
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          header="Hình ảnh (ENG)"
          body={(rowData) => imageBodyTemplate(rowData, "imageUrlEng")}
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          field="updatedDate"
          header="Thời gian cập nhật"
          body={(rowData) => new Date(rowData.updatedDate).toLocaleDateString()}
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          field="updatedByUserId"
          header="Người cập nhật"
          body={userBodyTemplate}
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          header="Hành động"
          body={(rowData) => (
            <Button
              label="Sửa"
              icon="pi pi-pencil"
              onClick={() => openDialog(rowData)}
              className="p-button-warning"
            />
          )}
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
      </DataTable>

      <Dialog
        visible={dialogVisible}
        onHide={closeDialog}
        header={<span className="text-3xl font-bold">Cập nhật About</span>}
        footer={
          <div className="flex justify-end gap-2">
            <Button
              label="Hủy"
              icon="pi pi-times"
              onClick={closeDialog}
              className="p-button-text"
            />
            <Button
              label="Cập nhật"
              icon="pi pi-check"
              onClick={handleUpdate}
            />
          </div>
        }
        className="bg-gray-800 p-4 text-white rounded-2xl"
        style={{ width: "50vw" }}
      >
        <div className="p-fluid">
          <div className="flex gap-4 mb-4">
            <div className="flex-1">
              <label htmlFor="imageUrl">Hình ảnh:</label>
              <input
                id="imageUrl"
                type="file"
                onChange={(e) => handleImageChange(e, "imageUrl")}
                className="p-inputtext p-component"
              />
              {formData.imageUrl && (
                <img
                  src={`data:image/jpeg;base64,${formData.imageUrl}`}
                  alt="Image Preview"
                  className="mt-2 w-full h-auto object-contain"
                />
              )}
            </div>

            <div className="flex-1">
              <label htmlFor="imageUrlEng">Hình ảnh (ENG):</label>
              <input
                id="imageUrlEng"
                type="file"
                onChange={(e) => handleImageChange(e, "imageUrlEng")}
                className="p-inputtext p-component"
              />
              {formData.imageUrlEng && (
                <img
                  src={`data:image/jpeg;base64,${formData.imageUrlEng}`}
                  alt="Image (ENG) Preview"
                  className="mt-2 w-full h-auto object-contain"
                />
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageAbout;
