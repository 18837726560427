import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify"; // Import toast directly
import { get, post, remove } from "../../services/axiosClient";

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [createUserDialogVisible, setCreateUserDialogVisible] = useState(false);
  const [newUser, setNewUser] = useState({
    username: "",
    password: "",
    fullName: "",
    email: "",
  });

  // Fetch users from API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userData = await get("User");
        setUsers(userData);
      } catch (error) {
        // console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  // Open user details dialog
  const openDialog = (user) => {
    setSelectedUser(user);
    setDialogVisible(true);
  };

  // Close user details dialog
  const closeDialog = () => {
    setDialogVisible(false);
    setSelectedUser(null);
  };

  // Open create user dialog
  const openCreateUserDialog = () => {
    setCreateUserDialogVisible(true);
  };

  // Close create user dialog
  const closeCreateUserDialog = () => {
    setCreateUserDialogVisible(false);
    setNewUser({
      username: "",
      password: "",
      fullName: "",
      email: "",
    });
  };

  // Handle user creation
  const handleCreateUser = async () => {
    try {
      await post("Auth/Signup", newUser);
      // Fetch users again to update the list
      const userData = await get("User");
      setUsers(userData);
      closeCreateUserDialog();
    } catch (error) {
      // console.error("Failed to create user:", error);
    }
  };

  // Handle user deletion
  const handleDeleteUser = async (userId) => {
    try {
      // console.log(`Attempting to delete user with ID: ${userId}`); // Debugging statement
      if (window.confirm("Bạn có chắc chắn muốn xóa người dùng này?")) {
        await remove(`User/${userId}`);
        // console.log("User deleted successfully"); // Debugging statement
        // Fetch users again to update the list
        const userData = await get("User");
        setUsers(userData);
      }
    } catch (error) {
      // console.error("Failed to delete user:", error);
      // alert("There was an error deleting the user. Please try again.");
      toast.error("Đã xảy ra lỗi khi xóa người dùng. Vui lòng thử lại!"); // Use toast directly
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Quản lí Users</h2>
      <Button
        label="Tạo mới User"
        icon="pi pi-user-plus"
        onClick={openCreateUserDialog}
        className="p-button-primary mb-4"
      />

      <DataTable
        value={users}
        paginator
        rows={5}
        selectionMode="single"
        selection={selectedUser}
      >
        <Column
          field="username"
          header="Username"
          className="border border-gray-300 p-2"
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          field="fullName"
          header="Full Name"
          className="border border-gray-300 p-2"
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          field="email"
          header="Email"
          className="border border-gray-300 p-2"
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
        <Column
          header="Hành động"
          body={(rowData) => (
            <div className="flex gap-2">
              <Button
                label="Thông tin chi tiết"
                icon="pi pi-info-circle"
                onClick={() => openDialog(rowData)}
              />
              <Button
                label="Xóa"
                icon="pi pi-trash"
                onClick={() => handleDeleteUser(rowData.userId)}
                className="p-button-danger"
              />
            </div>
          )}
          className="border border-gray-300 p-2"
          headerClassName="bg-gray-200 text-gray-800 border border-gray-300 p-2"
        />
      </DataTable>

      <Dialog
        visible={dialogVisible}
        onHide={closeDialog}
        header={
          <span className="text-3xl font-bold">Thông tin chi tiết User</span>
        }
        footer={
          <div className="flex justify-end gap-2 mt-4">
            <Button
              label="Thoát"
              icon="pi pi-times"
              onClick={closeDialog}
              className="p-button-text"
            />
          </div>
        }
        className="bg-gray-800 p-4 text-white rounded-2xl"
        style={{ width: "50vw" }}
      >
        {selectedUser && (
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="username">Username:</label>
              <input
                id="username"
                type="text"
                value={selectedUser.username}
                readOnly
                className="p-inputtext p-component text-gray-800"
              />
            </div>
            <div className="p-field">
              <label htmlFor="fullName">Full Name:</label>
              <input
                id="fullName"
                type="text"
                value={selectedUser.fullName}
                readOnly
                className="p-inputtext p-component text-gray-800"
              />
            </div>
            <div className="p-field">
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                type="text"
                value={selectedUser.email}
                readOnly
                className="p-inputtext p-component text-gray-800"
              />
            </div>
          </div>
        )}
      </Dialog>

      <Dialog
        visible={createUserDialogVisible}
        onHide={closeCreateUserDialog}
        header={<span className="text-3xl font-bold">Tạo mới User</span>}
        footer={
          <div className="flex justify-end gap-2">
            <Button
              label="Hủy"
              icon="pi pi-times"
              onClick={closeCreateUserDialog}
              className="p-button-text"
            />
            <Button
              label="Lưu"
              icon="pi pi-check"
              onClick={handleCreateUser}
              className="p-button-primary"
            />
          </div>
        }
        className="bg-gray-800 p-4 text-white rounded-2xl"
        style={{ width: "50vw" }}
      >
        <div className="p-fluid">
          <div className="p-field">
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              type="text"
              value={newUser.username}
              onChange={(e) =>
                setNewUser({ ...newUser, username: e.target.value })
              }
              className="p-inputtext p-component text-gray-800"
            />
          </div>
          <div className="p-field">
            <label htmlFor="password">Password:</label>
            <input
              id="password"
              type="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser({ ...newUser, password: e.target.value })
              }
              className="p-inputtext p-component text-gray-800"
            />
          </div>
          <div className="p-field">
            <label htmlFor="fullName">Full Name:</label>
            <input
              id="fullName"
              type="text"
              value={newUser.fullName}
              onChange={(e) =>
                setNewUser({ ...newUser, fullName: e.target.value })
              }
              className="p-inputtext p-component text-gray-800"
            />
          </div>
          <div className="p-field">
            <label htmlFor="email">Email:</label>
            <input
              id="email"
              type="text"
              value={newUser.email}
              onChange={(e) =>
                setNewUser({ ...newUser, email: e.target.value })
              }
              className="p-inputtext p-component text-gray-800"
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ManageUsers;
