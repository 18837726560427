import React from "react";
import { Route, Routes } from "react-router-dom";
import GuestLayout from "../layouts/GuestLayout";
import Home from "../pages/guest/Home";
import About from "../pages/guest/About";
import Products from "../pages/guest/Products";
import Contact from "../pages/guest/Contact";
import ScrollToTopButton from "../components/ScrollToTopButton"; // Import the ScrollToTopButton

const GuestRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<GuestLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
      {/* Render the ScrollToTopButton so it's available across all routes */}
      <ScrollToTopButton />
    </>
  );
};

export default GuestRoutes;
