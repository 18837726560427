import React, { useEffect, useState } from "react";
import { get } from "../../services/axiosClient";
import ProductSection from "../../components/ProductSection";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner"; // Import spinner của PrimeReact

function Products() {
  const { i18n } = useTranslation();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true); // Trạng thái loading

  useEffect(() => {
    get("/Product")
      .then((response) => {
        const activeProducts = response.filter((product) => product.isActive);
        setProducts(activeProducts);
        setLoading(false); // Kết thúc loading khi dữ liệu được tải
      })
      .catch((error) => {
      //   console.error("Error fetching products:", error);
        setLoading(false); // Kết thúc loading khi gặp lỗi
      });
  }, []);

  const getImageUrl = (product) =>
    i18n.language === "vi" ? product.homeImageUrl : product.homeImageUrlEng;

  return (
    <div className="Home">
      {loading ? ( // Hiển thị spinner khi đang tải dữ liệu
        <div className="flex justify-center items-center min-h-screen">
          <ProgressSpinner />
        </div>
      ) : (
        products.map((product) => (
          <Link key={product.productId} to={`/product/${product.productId}`}>
            <ProductSection
              img={getImageUrl(product)}
              bgColor={product.bgColor || "#FFFFFF"}
            />
          </Link>
        ))
      )}
    </div>
  );
}

export default Products;
